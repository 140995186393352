

import '@shoelace-style/shoelace/dist/themes/light.css';
import '@shoelace-style/shoelace/dist/themes/dark.css';
import '@shoelace-style/shoelace/dist/components/carousel/carousel.js';
import '@shoelace-style/shoelace/dist/components/details/details.js';
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path.js';

// Set the base path to the folder you copied Shoelace's assets to
setBasePath('/dist/shoelace');


const container = document.querySelector('.details-group');
if (container) {
    // Close all other details when one is shown
    container.addEventListener('sl-show', event => {
        [...container.querySelectorAll('sl-details')].map(details => (details.open = event.target === details));
    });
}
// <sl-button>, <sl-icon>, <sl-input>, and <sl-rating> are ready to use!
